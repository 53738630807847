<template>
  <div>
    <div class="flex justify-between items-center mb-6">
      <div>
        <h1 class="text-4xl">
          {{ isEditMode ? "Edit User" : "Create a new User" }}
        </h1>
      </div>
    </div>

    <div class="bg-white w-full p-6 rounded-lg shadow">
      <form class="w-full" @submit.prevent="isEditMode ? saveEdit : onSubmit">
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-first-name"
            >
              First Name
            </label>
            <input
              v-model="editedUser.name"
              :class="{ 'is-invalid': form.errors.has('name') }"
              class="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="grid-name"
              type="text"
              placeholder="Enter agent name"
            />
          </div>
          <div class="w-full md:w-1/2 px-3">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-phone"
            >
              Phone
            </label>
            <input
              v-model="editedUser.phone"
              :class="{ 'is-invalid': form.errors.has('phone') }"
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="phone"
              type="number"
              placeholder="Enter agent phone number"
            />
          </div>
        </div>

        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-type"
            >
              Type
            </label>

            <select
              v-model="editedUser.type"
              id="grid-type"
              class="bg-gray-200 w-full px-2 py-4"
              @change="handleType"
              name=""
            >
              <option value="admin">Admin</option>
              <option value="teller">Teller</option>
            </select>
          </div>

          <div class="w-full md:w-1/2 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="branch"
            >
              Branch
            </label>

            <input
              v-model="editedUser.branch"
              :class="{ 'is-invalid': form.errors.has('branch') }"
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="branch"
              type="text"
              placeholder="Enter branch"
            />
          </div>
        </div>

        <div class="flex flex-wrap -mx-3 px-3 mt-8">
          <button
            class="px-4 py-2 rounded text-white inline-block shadow-lg bg-blue-500 hover:bg-blue-600 focus:bg-blue-700"
            type="button"
            @click="saveEdit"
          >
            {{
              isLoading ? "Loading..." : isEditMode ? "Save Changes" : "Save"
            }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Form from "vform";

export default {
  data: () => ({
    isLoading: false,
    isEditMode: false,
    editedUser: {
      id: null,
      name: "",
      phone: "",
      type: "teller",
      branch: "",
      password: "",
    },
    form: new Form(),
  }),

  async created() {
    const userId = this.$route.query.id;
    console.log("this.$route.querythis.$route.query", this.$route.query);
    console.log("userIduserIduserId", userId);
    if (userId) {
      try {
        const response = await axios.get(`/agent/users/user/${userId}`);
        this.editedUser = response.data;
        this.isEditMode = true;
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    }
  },

  methods: {
    handleType(e) {
      this.editedUser.type = e.target.value;
    },

    async saveEdit() {
      console.log("this.editedUser", this.editedUser);
      try {
        await axios.patch(`/agent/users/user/edit`, this.editedUser);
        this.isEditMode = false;
        this.editedUser = {};
        this.$router.push({ path: "/agents" });
      } catch (error) {
        console.error("Error updating user:", error);
      }
    },
  },
};
</script>
